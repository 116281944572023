import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Icon,
  Heading,
  Flex,
  Text,
  Badge,
  ListItem,
  TabList,
  Tab,
  Tag,
  Tabs,
  TabPanel,
  TabPanels,
  Accordion,
  AccordionIcon,
  AccordionButton,
  AccordionPanel,
  AccordionItem,
  UnorderedList,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { MdHome } from 'react-icons/md'
import { FaFistRaised } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import ogImage from '../images/og_image.jpeg'

const SovieFragebogenPage = () => {
  return (
    <>
      <Helmet
        title="Sovie - SPÖ KandidatInnen Fragebogen"
        meta={[
          {
            name: `description`,
            content: `So haben die KandidatInnen für den SPÖ Bundesvorsitz auf unseren SoVie Fragebogen geantwortet.`,
          },
          {
            property: `og:title`,
            content: `Sovie - SPÖ KandidatInnen Fragebogen`,
          },
          {
            property: `og:url`,
            content: `https://www.sovie.or.at/sovie-fragebogen/`,
          },
          {
            property: `og:locale`,
            content: `de_DE`,
          },
          {
            property: `og:description`,
            content: `So haben die KandidatInnen für den SPÖ Bundesvorsitz auf unseren SoVie Fragebogen geantwortet.`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            property: `og:site_name`,
            content: `SoVie Österreich`,
          },
          {
            property: `article:publisher`,
            content: `https://www.facebook.com/SoVie-%C3%96sterreich-114495926645116/`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `sovie.or.at`,
          },
          {
            name: `twitter:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: `Sovie - SPÖ KandidatInnen Fragebogen`,
          },
          {
            name: `twitter:description`,
            content: `So haben die KandidatInnen für den SPÖ Bundesvorsitz auf unseren SoVie Fragebogen geantwortet.`,
          },
        ]}
      ></Helmet>
      <Box as="section" pt="5rem">
        <Box
          maxWidth="75rem"
          ml="auto"
          mr="auto"
          pl="1.5rem"
          pr="1.5rem"
        >
          <Breadcrumb
            spacing="8px"
            fontSize="sm"
            separator={<ChevronRightIcon color="red.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={GatsbyLink} to="/">
                <Icon as={MdHome} />
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>
                SPÖ KandidatInnen Fragebogen
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
      <Box as="section" pt="2rem" pb="7.5rem">
        <Box maxW="75rem" ml="auto" mr="auto" pl="1.5rem" pr="1.5rem">
          <Flex m="-.5rem" justifyContent="center" wrap="wrap">
            <Box p=".5rem" mb="1rem" flex="3 1 0">
              <Box pr={[null, null, '2']}>
                <Heading
                  as="h1"
                  fontSize={['3xl', '4xl', '5xl']}
                  ml="auto"
                  mr="auto"
                  lineHeight="1"
                >
                  <Box>SPÖ KandidatInnen Fragebogen</Box>
                </Heading>

                <Heading
                  as="h4"
                  fontSize={['md']}
                  fontWeight="normal"
                  ml="auto"
                  mr="auto"
                  my="0.5rem"
                  color="gray.700"
                >
                  <Box>
                    Die Standpunkte der SPÖ-Kandidat:innen zu
                    Vielfalt, Migration, Integration und Teuerung
                  </Box>
                </Heading>

                <Badge borderRadius="full" mr="2" colorScheme="red">
                  KandidatInnen
                </Badge>
                <Badge borderRadius="full" mr="2" colorScheme="red">
                  Fragebogen
                </Badge>
                <Badge borderRadius="full" colorScheme="red">
                  <Icon as={FaFistRaised} />
                </Badge>
                <Text
                  fontSize={['sm', 'md']}
                  mt="1.5rem"
                  opacity="0.7"
                >
                  Die Kandidat:innen für den SPÖ-Bundesvorsitz haben
                  unsere SoVie-Fragebögen zu den Themen Vielfalt,
                  Migration, Integration und Teuerung beantwortet. Wir
                  präsentieren Ihnen hier ihre Antworten.
                  <br />
                  <br />
                  Durch diese Fragebögen möchten wir unseren
                  Mitgliedern und Unterstützer:innen eine verlässliche
                  Informationsgrundlage zur Verfügung stellen, die
                  ihnen bei der bevorstehenden Vorsitzwahl helfen
                  kann, eine fundierte Entscheidung zu treffen.
                </Text>
              </Box>
              <Heading
                as="h3"
                fontSize={['md']}
                ml="auto"
                mr="auto"
                mb="1rem"
                mt="2rem"
              >
                <Box
                  display="flex"
                  flexDir="row"
                  alignItems="center"
                  style={{ columnGap: '1rem' }}
                >
                  Fragen und Antworten
                  <Tag
                    size={'md'}
                    key={'md'}
                    variant="solid"
                    colorScheme="teal"
                  >
                    10 Fragen
                  </Tag>
                </Box>
              </Heading>
              <Box boxShadow="base" rounded="md" bg="white">
                <Tabs colorScheme="red">
                  <TabList>
                    <Tab paddingTop="5" paddingBottom="5">
                      Pamela Rendi-Wagner
                    </Tab>
                    <Tab paddingTop="5" paddingBottom="5">
                      Hans-Peter Doskozil
                    </Tab>
                    <Tab paddingTop="5" paddingBottom="5">
                      Andreas Babler
                    </Tab>
                  </TabList>

                  <TabPanels padding="0">
                    <TabPanel padding="4">
                      <Accordion
                        allowToggle
                        colorScheme="red"
                        padding="0"
                        defaultIndex={0}
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Kennen sie den Verein SoVie –
                                Sozialdemokratische Vielfalt und
                                unterstützen Sie die Ziele von SoVie,
                                sowie eine statutarische Aufnahme in
                                die sozialdemokratische Familie als
                                befreundete Organisation?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            fontSize={{ base: 'sm', md: 'md' }}
                            px={{ base: '0', md: '12' }}
                          >
                            Ein sozial gerechtes Österreich, in dem
                            alle die gleichen Chancen und Perspektiven
                            haben, ist auch mein Ziel. Ich will das
                            Leben der Menschen verbessern. Deswegen
                            bin ich auch in die Politik gegangen.
                            <br />
                            <br />
                            Die Frage nach einer statutarischen
                            Aufnahme eures Vereins in die
                            sozialdemokratische Familie als
                            befreundete Organisation kann ohne
                            Kenntnis eures Vereinsstatuts nicht
                            beantwortet werden.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen wollen Sie gegen die
                                Inflation, explodierende Mieten usw.
                                setzen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Gaspreisdeckel für niedrigere Gas- und
                            Strompreise, befristetes Aus für
                            Mehrwertsteuer auf Grundnahrungsmittel,
                            Strom, Gas und Sprit. Mietenstopp für alle
                            Wohnungsmieten und Geschäftsmieten von KMU
                            bis Ende 2025 – danach Mieterhöhung auf
                            max. zwei Prozent begrenzen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie würden Sie sicherstellen, dass
                                Menschen mit Migrationsbiografie in
                                Österreich nicht nur gleiche Chancen
                                und Rechte haben, sondern auch aktiv
                                in politische Entscheidungsprozesse
                                einbezogen werden und ihre Anliegen
                                und Bedürfnisse angemessen vertreten
                                werden?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Als Sozialdemokratin versuche ich immer,
                            die Anliegen und Bedürfnisse aller
                            Menschen in unserem Land bestmöglich zu
                            vertreten und diese auch in die konkrete
                            politische Arbeit einfließen zu lassen.
                            Ich suche immer das Gemeinsame, so kann am
                            meisten für die Menschen in unserem Land
                            erreicht und das Leben der Menschen Stück
                            für Stück verbessert werden. Darüber
                            hinaus steht es auch allen frei, sich
                            politisch zu engagieren.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Derzeit spiegeln die
                                Entscheidungsstrukturen und das
                                Personal der Partei nicht die
                                Vielfältigkeit unserer Gesellschaft
                                wider. Wie viel Vielfalt wird in ihrem
                                zukünftigen Team stecken?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Mein Team besteht aus verschiedensten
                            Menschen mit ganz unterschiedlichen
                            Lebensgeschichten. Ich schätze diese
                            Vielfalt und die spannenden Diskussionen,
                            die sich dadurch ergeben, sehr. Unsere
                            Partei lebt ja von der Vielfalt, dem
                            Miteinander und dem offenen Diskurs. Aber
                            auch in der SPÖ ist die Gleichstellung und
                            Vielfalt noch nicht auf allen Ebenen
                            erreicht. Die Lebensrealitäten aller
                            Menschen müssen berücksichtigt und auch
                            abgebildet werden. Dafür mache ich mich
                            weiter stark.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sieht Ihrer Meinung nach eine
                                erfolgreiche Migrations- und
                                Integrationspolitik aus, die sowohl
                                den Bedürfnissen und Interessen der
                                aufnehmenden Gesellschaft als auch den
                                Rechten und Bedürfnissen der
                                Migrant:innen gerecht wird?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Eine erfolgreiche Migrations- und
                            Integrationspolitik stärkt den
                            Zusammenhalt und stellt das Gemeinsame vor
                            das Trennende. Dafür soll u.a. ein
                            Integrationsleitbild für Österreich
                            erstellt werden, mit Rechtsanspruch aber
                            auch persönlicher Verpflichtung zu
                            Integration ab dem ersten Tag.
                            Erstrebenswert ist auch die Einrichtung
                            eines Gesellschaftsfonds, der Maßnahmen in
                            Gemeinden, die das Miteinander stärken und
                            die bei allen Unterschieden eine Idee des
                            Gemeinsamen entwickeln, unterstützt. Denn
                            nur, wenn das Zusammenleben funktioniert,
                            wird auch die Integration von
                            Zugewanderten gelingen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Werden die Deutschförderklassen in
                                einer künftigen Bundesregierung mit
                                der SPÖ abgeschafft und durch
                                funktionierende Deutschfördermaßnahmen
                                ersetzt?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Wie auch ein Großteil der Pädagog*innen
                            bin ich für ein integratives Modell – also
                            Deutschförderung in der Klasse. Studien
                            haben belegt, dass Kinder in
                            Deutschförderklassen soziale Ausgrenzung
                            erleben, die Klassen sind mit
                            Schüler*innen verschiedener Altersgruppen
                            und mit verschiedenen Sprachkenntnissen
                            durchmischt und viel zu groß. Ein Großteil
                            der Pädagog*innen hat keine Ausbildung im
                            Bereich Deutsch als Zweitsprache. So kann
                            Deutschförderung nicht optimal
                            funktionieren.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Es gibt Menschen die hier geboren und
                                aufgewachsen sind und aufgrund ihrer
                                Arbeit im Niedriglohnsektor nicht die
                                Staatsbürgerschaft erlangen. Gedenken
                                sie hier Gesetze zu novellieren?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Finanzielle Hürden beim Erwerb der
                            Staatsbürgerschaft müssen abgebaut werden.
                            Es gibt Menschen, die im wichtigen
                            Pflegebereich jahrelang arbeiten, sich zu
                            100 Prozent zu Österreich bekennen, aber
                            nicht Österreicher*innen werden können,
                            weil die Einkommensvoraussetzungen und
                            Gebühren zu hoch sind. Die Erlangung der
                            Staatsbürgerschaft darf kein Privileg der
                            Reichen sein.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen planen Sie um der
                                Diskriminierung und Ausgrenzung
                                unserer Mitbürger:innen aufgrund
                                bestimmter Merkmale entgegenzusetzen
                                und den Schutz auszubauen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Es gibt den Diskriminierungsschutz im
                            Gesetz, dieser ist aber z.T. nicht
                            umfassend genug und muss ausgeweitet und
                            an manchen Stellen nachgeschärft werden.
                            Darüber hinaus ist es unsere
                            Verpflichtung, Hass und Diskriminierung
                            jeglicher Art entschieden
                            entgegenzutreten.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche konkreten Maßnahmen würden Sie
                                ergreifen, um die Integration von
                                Migrant:innen in Österreich zu fördern
                                und gleichzeitig sicherzustellen, dass
                                Frauen aus diesen Gruppen in vollem
                                Umfang von den Chancen und Freiheiten
                                unserer Gesellschaft profitieren
                                können?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Ziel der SPÖ ist es, dass sich alle
                            Menschen, die das Recht haben, hier zu
                            leben, in Österreich entfalten können.
                            Jede*r von ihnen muss die Möglichkeit
                            haben, im selben Ausmaß an unserer
                            Gesellschaft teilzuhaben. Bildung in
                            jungen Jahren ist der Schlüssel zur
                            Integration. <br /> <br />
                            Daher fordert die SPÖ u.a. die Verteilung
                            von zusätzlichen Integrationsmitteln
                            (Sozialarbeiter*innen, Sprachtrainings) in
                            Schulen mittels eines Chancenindex und
                            Jugendcolleges in allen Bundesländern für
                            Jugendliche, die nicht mehr schulpflichtig
                            sind. Auch die Integration in den
                            Arbeitsmarkt, hier fordert die SPÖ u.a.
                            eine Ausbildungs- und
                            Qualifizierungsoffensive für Menschen mit
                            geringer Qualifikation, ist ein
                            wesentlicher Hebel zur gesellschaftlichen
                            Integration.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sollten aus Ihrer Sicht die
                                Aufnahme und Integration von
                                Geflüchteten in Österreich erfolgen,
                                um einerseits humanitäre Verantwortung
                                zu übernehmen und andererseits die
                                Herausforderungen für unsere
                                Gesellschaft zu bewältigen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Die Asyl- und Migrationspolitik der
                            Regierungen der letzten fünf Jahre ist
                            geprägt von Inszenierungen und Populismus.
                            Neue Rückübernahmeabkommen wurden nicht
                            ausgehandelt, der Schutz der
                            EU-Außengrenzen wurde vernachlässigt.
                            Humanität und Kontrolle müssen 2023 die
                            zentralen Grundsätze einer Asyl- und
                            Migrationspolitik in Österreich und in
                            Europa werden. Dazu braucht es Allianzen
                            in Europa, um gemeinsam Schritte für ein
                            neues Asyl- und Migrationssystem zu
                            setzen. Die große Herausforderung besteht
                            darin, lebensgefährliche, irreguläre
                            Migration zu reduzieren, ohne die
                            Menschenrechte oder die Menschenwürde zu
                            verletzen. Das geht nur durch
                            Kooperationsangebote gegenüber Herkunfts-
                            und Transitstaaten. Es ist im Interesse
                            Österreichs, eine solche Politik
                            voranzutreiben. Weitere Ideen für die
                            Asyl- und Migrationspolitik gibt es im
                            SPÖ-Maßnahmenplan zu Flucht, Asyl,
                            Migration und Integration.
                            (https://www.spoe.at/2022/11/23/spoe-hat-klaren-massnahmen-plan-zu-asyl-und-
                            migration/)
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </TabPanel>
                    <TabPanel padding="4">
                      <Accordion
                        allowToggle
                        colorScheme="red"
                        padding="0"
                        defaultIndex={0}
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Kennen sie den Verein SoVie –
                                Sozialdemokratische Vielfalt und
                                unterstützen Sie die Ziele von SoVie,
                                sowie eine statutarische Aufnahme in
                                die sozialdemokratische Familie als
                                befreundete Organisation?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Die Gleichstellung und Gleichberechtigung
                            der Menschen, Chancengleichheit und das
                            Recht auf ein würdevolles und
                            selbstbestimmtes Leben sind Grundwerte,
                            die mit aller Kraft vorangetrieben werden
                            müssen. SoVie vertritt diese Werte aus
                            meiner Sicht auf sehr moderne und
                            innovative Art und Weise. Eine
                            statutarische Aufnahme in die
                            sozialdemokratische Familie als
                            befreundete Organisation wäre eine
                            Bereicherung und soll dementsprechend in
                            die Wege geleitet werden.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen wollen Sie gegen die
                                Inflation, explodierende Mieten usw.
                                setzen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Hier muss man sagen, dass die
                            Bundesregierung einfach zu wenig und zu
                            spät reagiert hat. Während andere Staaten
                            längst in den Markt eingegriffen und damit
                            die Inflation nachhaltig gesenkt haben,
                            leben immer mehr Österreicherinnen und
                            Österreicher am Existenzminimum. Darunter
                            sind armutsgefährdete Kinder, Frauen,
                            Pensionistinnen und Pensionisten,
                            Arbeiterinnen und Arbeiter. Statistik
                            Austria hat erst kürzlich Armutsdaten
                            veröffentlicht: 71.000 Menschen in
                            Österreich sind armutsgefährdet, obwohl
                            sie mehr als 34 Stunden arbeiten gehen.
                            Rund 1,5 Millionen Menschen sind
                            hierzulande von Armut bedroht, täglich
                            werden es mehr. Auch im Burgenland mussten
                            wir die Fehler der Bundesregierung mit
                            Maßnahmen ausbügeln: So haben wir
                            beispielsweise einen sozial gestaffelten
                            Wärmepreisdeckel eingeführt, die
                            Burgenland Energie hat günstigere
                            Fixtarife für Strom und Gas angeboten, und
                            wir haben eine Mietpreisbremse eingeführt.
                            Um der Bevölkerung auch im
                            Lebensmittelbereich günstigere Optionen
                            anzubieten, haben wir flächendeckend
                            Sozialmärkte eröffnet.
                            <br />
                            <br />
                            Aber eines muss an dieser Stelle auch
                            festgehalten werden: Die Aufgabe einer
                            zukünftigen sozialdemokratischen Regierung
                            darf es nicht sein, sich für solche
                            Maßnahmen zu rühmen, sie waren nur
                            notwendig, weil die Bundesregierung hier
                            völlig versagt hat. Die Aufgabe der SPÖ
                            ist es nicht, Almosen zu verteilen,
                            sondern dafür zu sorgen, dass Menschen von
                            ihrem Gehalt leben können. Ich stehe
                            hinter meinen Forderungen in diesem
                            Bereich ganz klar: Mindestlohn von 2.000
                            Euro netto, der soziale Wohnbau muss
                            gemeinnützig sein. Es braucht
                            flächendeckend eine ganztägige kostenlose
                            Kinderbetreuung. Die ÖVP ist nur schnell,
                            wenn es um die Unterstützung ihrer
                            Klientel oder Spender geht, die normale
                            Bevölkerung macht sie zu Bittstellern, die
                            sich durch unzählige Anträge kämpfen muss,
                            um das normale Leben zu bestreiten. Das
                            muss aufhören und dafür braucht es eine
                            starke SPÖ!
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie würden Sie sicherstellen, dass
                                Menschen mit Migrationsbiografie in
                                Österreich nicht nur gleiche Chancen
                                und Rechte haben, sondern auch aktiv
                                in politische Entscheidungsprozesse
                                einbezogen werden und ihre Anliegen
                                und Bedürfnisse angemessen vertreten
                                werden?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Die Partei gehört nicht einigen wenigen
                            Funktionärinnen und Funktionären, die in
                            Hinterzimmern ausmachen, was passiert.
                            Genau das ist es, was mir seit längerem in
                            unserer Partei aufstößt, was ich seit
                            längerem kritisiere. Bislang hat man den
                            Meinungen der Basis – die sehr vielfältig
                            sein können – nicht viel Gehör geschenkt.
                            Die Partei gehört aber den Mitgliedern!
                            Wer Vorsitzender oder Vorsitzende ist, wer
                            Spitzenkandidat oder Spitzenkandidatin
                            wird oder wie Koalitionsabkommen aussehen:
                            all das ist meiner Meinung nach einer
                            Mitgliederbefragung zu unterziehen – so
                            soll unsere Partei mit Demokratie geflutet
                            werden. Dann können wir auch garantieren,
                            dass jede Stimme gleich viel wert ist:
                            Unabhängig von Herkunft, Geschlecht oder
                            Funktion. Mein klares Ziel ist es, die
                            Partei in seiner Vielfalt zu einen und den
                            Bogen über die ganze Partei zu spannen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Derzeit spiegeln die
                                Entscheidungsstrukturen und das
                                Personal der Partei nicht die
                                Vielfältigkeit unserer Gesellschaft
                                wider. Wie viel Vielfalt wird in ihrem
                                zukünftigen Team stecken?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Ich möchte die Sozialdemokratie wieder zu
                            einer Partei der Vielen machen und mein
                            Team auch möglichst breit aufstellen. Wir
                            leben in herausfordernden Zeiten,
                            historisch betrachtet braucht man gerade
                            in solchen Phasen mehr denn je eine
                            Politik, die einerseits
                            Rechtsstaatlichkeit und Fairness als
                            oberste Gebote lebt, die aber andererseits
                            den Zusammenhalt fördert und das
                            Gemeinsame vor das Trennende stellt. Wir
                            müssen das öffentliche Bewusstsein für die
                            Probleme der Bürgerinnen und Bürger mit
                            Migrationsbiografie weiter schärfen. Es
                            geht einerseits um ihre Lebens- und
                            Erwerbssituation sowie auch um ihre
                            gesellschaftliche Stellung.
                            Chancengleichheit muss es in einem
                            modernen Staat für alle geben. Wir haben
                            in der SPÖ viele Genossinnen und Genossen
                            mit unterschiedlichsten Lebensgeschichten,
                            die großes Talent und den Willen
                            mitbringen, für eine starke
                            Sozialdemokratie und ein gerechtes
                            Österreich zu arbeiten. Sie sollen auch
                            Gehör, Einfluss und politische Funktionen
                            bekommen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sieht Ihrer Meinung nach eine
                                erfolgreiche Migrations- und
                                Integrationspolitik aus, die sowohl
                                den Bedürfnissen und Interessen der
                                aufnehmenden Gesellschaft als auch den
                                Rechten und Bedürfnissen der
                                Migrant:innen gerecht wird?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            „Fördern und fordern“ - so lautet meine
                            Devise. Die Einhaltung der Gesetze ist in
                            einem Rechtsstaat wie Österreich enorm
                            wichtig, das habe ich übrigens bereits im
                            SPÖ- Integrationspapier gemeinsam mit
                            Peter Kaiser so definiert, ebenso die
                            Forderung nach Asyl-Aufnahmezentren an den
                            EU-Außengrenzen. Wenn an der
                            EU-Außengrenze über den Asylstatus
                            entschieden wird, klappt auch die
                            Verteilung von Flüchtenden auf die gesamte
                            Union besser. Unser Leitfaden gilt nach
                            wie vor. Die Zeiten haben sich aber gerade
                            in jüngster Vergangenheit sehr geändert.
                            Es gibt zwei Themen, die mehr denn je
                            entkoppelt voneinander betrachtet werden
                            müssen: Arbeitsmigration und Asyl. Nur,
                            wenn wir das trennen, können wir in der
                            Migrationspolitik zielgerichtet und klar
                            reagieren. Klar ist, dass wir in vielen
                            Branchen qualifizierte Zuwanderung
                            brauchen, siehe den Sektor Pflege. Ich bin
                            daher für gezielte Zuwanderung. Bei der
                            Rekrutierung von Arbeitskräften im Ausland
                            reicht aber die Rot-Weiß-Rot-Karte nicht
                            aus.
                            <br />
                            <br />
                            Wenn möglich, sollen bereits im
                            Herkunftsland Deutschkurse organisiert
                            werden. Populistische Maßnahmen der ÖVP,
                            wie beispielsweise die medial
                            bekanntgewordene Abschiebung der indischen
                            Familie – die Mutter hat im Pflegebereich
                            gearbeitet – müssen aufhören. Eines ist
                            aber auch klar: Das Kokettieren der FPÖ
                            mit „Ausländer raus!“- Forderungen ist mit
                            den Menschenrechten nicht vereinbar:
                            Menschen, denen in ihrer Heimat Verfolgung
                            oder sogar der Tod droht, müssen, das gibt
                            uns auch das Gesetz vor, geschützt werden.
                            Das ist unsere humanitäre Verantwortung.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Werden die Deutschförderklassen in
                                einer künftigen Bundesregierung mit
                                der SPÖ abgeschafft und durch
                                funktionierende Deutschfördermaßnahmen
                                ersetzt?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Wer dauerhaft in Österreich lebt, soll
                            bestmöglich integriert werden. Dazu
                            braucht es die Sprache. Für Kinder, die
                            aufgrund mangelnder Deutschkenntnisse dem
                            Unterricht nicht folgen können, sollte man
                            die 2018 eingeführten Deutschförderklassen
                            und Deutschförderkurse aber in dieser Form
                            überdenken. Daher kommt im Burgenland auch
                            bereits der integrative Unterricht zur
                            Anwendung. Der Vorteil gegenüber
                            Deutschklassen ist, dass die Kinder nicht
                            ausgegrenzt, sondern in den Schulalltag
                            integriert werden. Inklusion statt
                            Isolation ist mein Zugang.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Es gibt Menschen die hier geboren und
                                aufgewachsen sind und aufgrund ihrer
                                Arbeit im Niedriglohnsektor nicht die
                                Staatsbürgerschaft erlangen. Gedenken
                                sie hier Gesetze zu novellieren?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Die geltenden Regelungen betreffend der
                            Aufenthaltsdauer und inhaltlichen
                            Anforderungen sollten nicht geändert
                            werden. Die Fristen für die
                            Staatsbürgerschaft – 10 oder 6 Jahre –
                            sind in Ordnung, denn die
                            Staatsbürgerschaft muss aus meiner Sicht
                            etwas Besonderes bleiben. Eine Senkung der
                            Kosten wäre aber jedenfalls ein
                            vernünftiger Schritt. Bei
                            Doppelstaatsbürgerschaften könnte man das
                            Gesetz weiterentwickeln. Es ist
                            verständlich, dass Menschen, die jahrelang
                            dauerhaft in Österreich leben, sowohl die
                            Verbundenheit zu ihrem Herkunftsland als
                            auch zu Österreich leben wollen. Die
                            bestehenden Möglichkeiten für die
                            Doppelstaatsbürgerschaften könnten unter
                            bestimmten Voraussetzungen erweitert und
                            der Zugang erleichtert werden. Auch bei
                            den Einkommensgrenzen und
                            Wohnverhältnissen braucht es
                            Erleichterungen. In diesem Zusammenhang
                            kommt die Umsetzung des Mindestlohns ins
                            Spiel. Damit wäre auch ein zentraler Hebel
                            bei der aktuellen Diskussion erledigt, wo
                            es um die Herabsetzung der
                            Einkommensgrenzen bei der Erlangung der
                            Staatsbürgerschaft geht.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen planen Sie um der
                                Diskriminierung und Ausgrenzung
                                unserer Mitbürger:innen aufgrund
                                bestimmter Merkmale entgegenzusetzen
                                und den Schutz auszubauen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Diskriminierung von Menschen aufgrund
                            ihrer Herkunft, ihres Geschlechtes oder
                            ihrer sexuellen Orientierung ist strikt
                            abzulehnen und auf allen Ebenen
                            entgegenzuwirken. Der Schlüssel für
                            Integration ist Sprache: Daher setze ich
                            mich auch dafür ein, dass Deutschkurse für
                            alle Zuwanderer kostenfrei sind, bis sie
                            die deutsche Sprache beherrschen. Es gilt
                            aber Integration statt Isolation. Deshalb
                            sind Maßnahmen wie die Abschaffung der
                            Deutschförderklassen ein wichtiger
                            Schritt, um Integration bereits im jungen
                            Alter zu gewährleisten.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche konkreten Maßnahmen würden Sie
                                ergreifen, um die Integration von
                                Migrant:innen in Österreich zu fördern
                                und gleichzeitig sicherzustellen, dass
                                Frauen aus diesen Gruppen in vollem
                                Umfang von den Chancen und Freiheiten
                                unserer Gesellschaft profitieren
                                können?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Österreich bekennt sich ganz klar zur
                            Gleichberechtigung zwischen Mann und Frau.
                            In der gesamten Migrationsfrage braucht es
                            aus meiner Sicht klare Linien, auf Basis
                            der Rechtsstaatlichkeit. Für mich ist
                            klar, dass man unsere Gesetze und Werte
                            leben muss. In diesem Zusammenhang sind
                            Werte wie Freiheit, Gleichheit und
                            Gerechtigkeit zu nennen, die einzuhalten
                            sind. Dazu gehört auch die
                            Gleichberechtigung von Frauen. Ein
                            barrierefreier Zugang zu
                            Bildungseinrichtungen, der Kampf gegen das
                            Zwei-Klassen- Bildungssystem, der
                            ganztägige Gratis-Kindergarten und der
                            Mindestlohn sind Maßnahmen, die ganz klar
                            dazu beitragen, dass Frauen, egal woher
                            sie kommen, gleiche Chancen und die
                            Freiheit, ihr eigenes Lebenskonzept zu
                            verwirklichen, haben.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sollten aus Ihrer Sicht die
                                Aufnahme und Integration von
                                Geflüchteten in Österreich erfolgen,
                                um einerseits humanitäre Verantwortung
                                zu übernehmen und andererseits die
                                Herausforderungen für unsere
                                Gesellschaft zu bewältigen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Seit dem Angriffskrieg Russlands gegen die
                            Ukraine haben wir im Burgenland einmal
                            mehr Verantwortung übernommen und
                            vorgezeigt, wie humanitäre Verantwortung
                            im Rahmen der Neutralität aussieht. Das
                            Burgenland hat Hilfsgüter geliefert,
                            Vertriebene mit Bussen ins Burgenland
                            geholt und aufgenommen, alles im Rahmen
                            unserer Möglichkeiten. Das Burgenland
                            leistet im Österreich-Vergleich einen
                            besonders hohen Beitrag, wenn es um die
                            Aufnahme von Geflüchteten geht.
                            Langfristig muss man davon ausgehen, dass
                            auch mit dem Klimawandel die
                            Fluchtbewegung zunehmen wird. Das zeigen
                            sämtliche Prognosen von Expertinnen und
                            Experten und Österreich alleine wird die
                            Verantwortung nicht tragen können, ohne
                            unsere Gesellschaft zu überfordern. Daher
                            werden die Asylzentren außerhalb der EU
                            auch immer wichtiger, um Menschen mit
                            tatsächlichen Asylgründen in Sicherheit zu
                            bringen und gleichzeitig der
                            Schlepper-Kriminalität entgegenzuwirken.
                            Es braucht einen gesamteuropäischen
                            Lösungsweg, nur so wird es möglich sein,
                            diese Herausforderungen zu bewältigen. Der
                            Sinn eines Staatenbundes ist es, diese
                            grenzübergreifenden Probleme zu lösen. Die
                            EU aber auch der Bund müssen endlich im
                            Bereich des Flüchtlingswesens gangbare
                            Lösungen anbieten, vor dem Hintergrund,
                            dass wir auch in Österreich mit vielen
                            Herausforderungen konfrontiert sind:
                            Teuerung, Energiekrise, Armutsgefährdung.
                            Dafür werde ich mich auch zukünftig
                            einsetzen.
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </TabPanel>
                    <TabPanel padding="4">
                      <Accordion
                        allowToggle
                        colorScheme="red"
                        padding="0"
                        defaultIndex={0}
                      >
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Kennen sie den Verein SoVie –
                                Sozialdemokratische Vielfalt und
                                unterstützen Sie die Ziele von SoVie,
                                sowie eine statutarische Aufnahme in
                                die sozialdemokratische Familie als
                                befreundete Organisation?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Allen Menschen ein würdevolles und
                            selbstbestimmtes Leben zu ermöglichen, ist
                            die Politik, für die ich stehe. Meine
                            Zielsetzungen decken sich also mit jenen
                            von SoVie. Dementsprechend kann mir
                            vorstellen, eine Aufnahme von SoVie zu
                            unterstützen – möchte und muss diese
                            Entscheidung aber den Gremien überlassen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen wollen Sie gegen die
                                Inflation, explodierende Mieten usw.
                                setzen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Der Wohnungsmarkt und der Energiemarkt
                            sind die größten Preistreiber, gerade die
                            Lebensmittelpreise explodieren. Die
                            Bundesregierung hat bisher verabsäumt,
                            hier inflationsdämpfend einzugreifen,
                            obwohl andere Länder vorgezeigt haben, wie
                            das gehen kann.
                            <br />
                            <br />
                            Wir müssen die Gewinn-Preis-Spirale
                            durchbrechen. Dazu brauchen wir:
                            Mietobergrenzen für Wohnungen und
                            Geschäftsräume von kleinen und mittleren
                            Unternehmen; eine Energiegrundsicherung,
                            um die Energieversorgung nicht dem Markt
                            zu überlassen; eine effektive Besteuerung
                            von Übergewinnen; sowie eine
                            Preis-Kommission mit Biss, die Preise
                            nicht nur beobachtet, sondern auch aktiv
                            eingreift.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie würden Sie sicherstellen, dass
                                Menschen mit Migrationsbiografie in
                                Österreich nicht nur gleiche Chancen
                                und Rechte haben, sondern auch aktiv
                                in politische Entscheidungsprozesse
                                einbezogen werden und ihre Anliegen
                                und Bedürfnisse angemessen vertreten
                                werden?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Demokratiepolitisch ist untragbar, dass
                            immer mehr Arbeiter:innen in Österreich
                            nicht wahlberechtigt sind, weil ihnen die
                            österreichische Staatsbürger:innenschaft
                            fehlt. Daher müssen die sozialen,
                            finanziellen und bürokratischen Hürden zur
                            Staatsbürger:innenschaft beseitigt werden.
                            Wer hier lebt und arbeitet, muss auch
                            mitbestimmen dürfen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Derzeit spiegeln die
                                Entscheidungsstrukturen und das
                                Personal der Partei nicht die
                                Vielfältigkeit unserer Gesellschaft
                                wider. Wie viel Vielfalt wird in ihrem
                                zukünftigen Team stecken?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Ich bin derzeit sehr bewegt, wie viele
                            Menschen sich einbringen, weil sie
                            Hoffnung schöpfen, dass unsere Partei
                            wieder Stolz und Kraft gewinnen kann. Ich
                            kandidiere, weil ich nach Jahren des
                            internen Kleinkriegs allen Kräften in
                            unserer Partei die Hand reichen möchte, um
                            zusammen Politik für die Vielen zu
                            gestalten. Diese „Vielen“ bedingen
                            selbstverständlich auch Vielfalt – nicht
                            nur in meinem Team, sondern in allen
                            Gremien der Partei.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sieht Ihrer Meinung nach eine
                                erfolgreiche Migrations- und
                                Integrationspolitik aus, die sowohl
                                den Bedürfnissen und Interessen der
                                aufnehmenden Gesellschaft als auch den
                                Rechten und Bedürfnissen der
                                Migrant:innen gerecht wird?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Es ist für mich eine Frage des Respekts,
                            allen Menschen die gleichberechtigte
                            Teilhabe am gesellschaftlichen Leben zu
                            ermöglichen. Egal, welche Hautfarbe oder
                            Herkunft. Egal, ob neu im Land oder immer
                            schon da. Egal, ob man am Bau arbeitet
                            oder im Büro. Jeder Mensch verdient
                            Respekt. Dafür mache ich mich in
                            Traiskirchen stark und dafür will ich auch
                            auf Bundesebene kämpfen.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Werden die Deutschförderklassen in
                                einer künftigen Bundesregierung mit
                                der SPÖ abgeschafft und durch
                                funktionierende Deutschfördermaßnahmen
                                ersetzt?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Die Abschaffung der Deutschförderklassen
                            ist unbedingt notwendig. Die Rückmeldungen
                            der Expertinnen und Pädagogen:innen zu den
                            Deutschförderklassen sind verheerend. 98%
                            der Schulleiter:innen und 91% der
                            Sprachförderlehrkräfte wünschen sich mehr
                            Flexibilität bei der Art der Förderung und
                            einen leichteren Wechsel zwischen
                            Förderklassen und Regelklassen. Die
                            Förderklassen sind v.a. eine populistische
                            Symbolaktion von Schwarz-Blau gewesen und
                            kein ernstgemeinter Versuch, die
                            Deutschkenntnisse von Kindern rasch und
                            gezielt zu verbessern.
                            <br />
                            <br />
                            Je nachdem, wann ein Kind oder einE
                            JugendlicheR in Österreich in das
                            Bildungssystem einsteigt und was er oder
                            sie mitbringt an Erlebtem und Gelerntem,
                            braucht es unterschiedliche Lernsettings.
                            Wenn Kinder in Österreich aufgewachsen
                            sind, ist es zielführend, wenn sie viel
                            Zeit mit Kindern verbringen, die Deutsch
                            als Erstsprache sprechen und Lehrkräfte
                            haben, die mit den zentralen DaZ-Konzepten
                            vertraut sind und auch die zeitlichen
                            Ressourcen haben, sich entsprechend um
                            diese Kinder zu kümmern.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Es gibt Menschen die hier geboren und
                                aufgewachsen sind und aufgrund ihrer
                                Arbeit im Niedriglohnsektor nicht die
                                Staatsbürgerschaft erlangen. Gedenken
                                sie hier Gesetze zu novellieren?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Jeder fünfte in Österreich lebende Mensch
                            und in Wien sogar jeder dritte haben keine
                            österreichische Staatsbürger:innenschaft.
                            Viele haben sie nicht, obwohl sie seit
                            Jahrzehnten hier leben und arbeiten – aber
                            eben in Jobs in der Pflege, der Reinigung
                            oder am Bau, in denen sie zu wenig
                            verdienen, um die Einkommensgrenzen zu
                            erfüllen. Wir müssen die Einkommenshürden
                            senken.
                            <br />
                            <br />
                            Gleichzeitig müssen wir alles tun, um die
                            Löhne zu heben. Gerade im
                            Niedriglohnbereich betrifft das die
                            Arbeitskräfteüberlassung, Lohn- und
                            Sozialdumping und andere
                            Umgehungsmöglichkeiten des Arbeitsrechts.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche Maßnahmen planen Sie um der
                                Diskriminierung und Ausgrenzung
                                unserer Mitbürger:innen aufgrund
                                bestimmter Merkmale entgegenzusetzen
                                und den Schutz auszubauen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            In Österreich hat das bestehende
                            Gleichbehandlungsrecht grundsätzlich eine
                            schützende Funktion, besonders in den
                            Bereichen Arbeitswelt, Wohnraum, Bildung
                            und Gesundheit.
                            <br />
                            <br />
                            Der Schutz vor Diskriminierung in
                            Beschäftigung und Beruf aufgrund des
                            Geschlechts, der ethnischen Zugehörigkeit,
                            der sexuellen Orientierung, des Alters,
                            der Behinderung der Religion oder
                            Weltanschauung ist in Österreich
                            gesetzlich umgesetzt.
                            <br />
                            <br />
                            Außerhalb der Arbeitswelt gibt es große
                            Lücken – hier sind nur die Merkmale
                            Geschlecht und ethnische Herkunft
                            geschützt. Schutz muss es auch für die
                            Merkmale der sexuellen Orientierung, des
                            Alters sowie der Religion oder
                            Weltanschauung geben.
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Welche konkreten Maßnahmen würden Sie
                                ergreifen, um die Integration von
                                Migrant:innen in Österreich zu fördern
                                und gleichzeitig sicherzustellen, dass
                                Frauen aus diesen Gruppen in vollem
                                Umfang von den Chancen und Freiheiten
                                unserer Gesellschaft profitieren
                                können?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Zahlreiche Befunde belegen, dass Frauen,
                            unabhängig vom Migrationshintergrund,
                            insbesondere am Arbeitsmarkt Nachteile
                            erleben: Sie haben geringere
                            Aufstiegschancen, als Teilzeitbeschäftigte
                            haben sie geringere Stundenlöhne, sie
                            erhalten seltener Weiterbildung und sind
                            seltener in Führungspositionen. Mit
                            Kindern erleben Mütter, insbesondere
                            Alleinerzieherinnen, einen erschwerten
                            Wiedereinstieg.
                            <br />
                            <br />
                            Daher müssen strukturelle
                            Rahmenbedingungen geschaffen werden, um
                            echte Chancengleichheit zu ermöglichen –
                            etwa bei Bildung, Unterstützung bei der
                            Arbeitssuche und dem Zugang zu leistbarem
                            Wohnraum.
                            <br />
                            <br />
                            Konkret bedeutet das:
                            <UnorderedList>
                              <ListItem>
                                Familienfreundliche Arbeitszeiten
                              </ListItem>
                              <ListItem>
                                Volle Lohntransparenz
                              </ListItem>
                              <ListItem>
                                Ausreichende, hochwertige und
                                ganztägige Kinderbildungseinrichtungen
                              </ListItem>
                              <ListItem>
                                Durchlässige Bildungssysteme
                              </ListItem>
                              <ListItem>
                                Unterstützungsangebote zur Beseitigung
                                von Arbeitsmarktbarrieren
                              </ListItem>
                              <ListItem>
                                Schaffung ausreichender Arbeitsplätze
                                mit guten Löhnen und
                                Arbeitsbedingungen
                              </ListItem>
                              <ListItem>
                                Anerkennung von ausländischen
                                Abschlüssen von Migrant:innen
                              </ListItem>
                              <ListItem>
                                Abbau von Vorurteilen bei der
                                Jobsuche, Wohnungssuche etc.
                              </ListItem>
                            </UnorderedList>
                          </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <h2>
                            <AccordionButton
                              paddingTop="1.5rem"
                              paddingBottom="1.5rem"
                              _expanded={{
                                fontWeight: 600,
                                backgroundColor: '#E2E8F0',
                              }}
                              fontSize={{ base: 'sm', md: 'md' }}
                            >
                              <AccordionIcon mr="1rem" />
                              <Box flex="1" textAlign="left">
                                Wie sollten aus Ihrer Sicht die
                                Aufnahme und Integration von
                                Geflüchteten in Österreich erfolgen,
                                um einerseits humanitäre Verantwortung
                                zu übernehmen und andererseits die
                                Herausforderungen für unsere
                                Gesellschaft zu bewältigen?
                              </Box>
                            </AccordionButton>
                          </h2>
                          <AccordionPanel
                            px={{ base: '0', md: '12' }}
                            fontSize={{ base: 'sm', md: 'md' }}
                          >
                            Ich habe erlebt, wie in einem Zelt im
                            Garten unseres Flüchtlingslagers in
                            Traiskirchen ein Kind zur Welt gekommen
                            ist. Ich habe aber auch erlebt, wie
                            Menschen mit nichts hier angekommen sind,
                            in Traiskirchen geblieben sind, sich eine
                            Existenz aufgebaut haben. Mittlerweile
                            schaffen sie als erfolgreiche
                            Selbstständige selbst Arbeitsplätze, sind
                            zu engen Freunden geworden und engagieren
                            sich selbst in der Flüchtlingshilfe.
                            <br />
                            <br />
                            Und das ist bitter notwendig. Denn es
                            mangelt den untergebrachten Menschen am
                            notwendigsten: Warme Schuhe und Kleidung
                            im Winter. Kinder laufen in Schlapfen
                            durch den Schnee. Schwangere bekommen
                            nicht ausreichend vitaminreiche Kost. Wo
                            die Bundesbetreuung absichtlich untragbare
                            Situationen schafft und auf die
                            Menschenwürde pfeift, da helfen wir. Denn
                            kein Mensch ist illegal.
                            <br />
                            <br />
                            Der Punkt ist aber: Eigentlich sollte
                            unsere Hilfe gar nicht notwendig sein. Es
                            sollte in einem reichen Land wie
                            Österreich selbstverständlich sein, dass
                            auch geflüchtete Menschen ein
                            menschenwürdiges Leben führen können, ohne
                            dass es ihnen an den notwendigsten Dingen
                            fehlt. Dazu gehören auch faire
                            Asylverfahren, die in einer zumutbaren
                            Zeit abgewickelt werden.
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default SovieFragebogenPage
